const routes = {
    publate: [
        {path: '/sales/:saleId/:saleToken', exact: false, secured: false, screen: 'private_sale'},
        {path: '/sponsorings/:sponsoringId/:sponsoringToken', exact: false, secured: false, screen: 'private_sponsoring'},
        {path: '/books/:bookId/:publicToken', exact: false, secured: false, screen: 'private_book'},
        {path: '/invitations/:jwtToken', exact: false, secured: false, screen: 'invitation'},
        {path: '/groupings/:id/:publicToken', exact: false, secured: false, screen: 'private_grouping'},
        {path: '/partners/:code/register/game', exact: true, secured: false, screen: 'public/public_partner_register_game'},
        {path: '/partners/:code/register/sponsor', exact: true, secured: false, screen: 'public/public_partner_register_sponsor'},
        {path: '/partners/:code/register/donee', exact: true, secured: false, screen: 'public/public_partner_register_donee'},
        {path: '/partners/:code/register', exact: true, secured: false, redirect: './register/game'},
        {path: '/partners/:code/idp/email', exact: true, secured: false, screen: 'public/public_partner_idp_email'},
        {path: '/register/donee', exact: true, secured: false, redirect: `/partners/gotombola/register/donee`},
        {path: '/register/sponsor', exact: true, secured: false, redirect: `/partners/gotombola/register/sponsor`},
        {path: '/register/game', exact: true, secured: false, redirect: `/partners/gotombola/register/game`},
        {path: '/partners/:code/register/game/idps/:mode', exact: true, secured: false, screen: `public/public_partner_register_game_idps`},
        {path: '/register', exact: true, secured: false, redirect: `/register/game`},
        {path: '/registrations/:id/:privateToken/:stepName?', exact: false, secured: false, screen: 'private/registration/private_registration'},
        {path: '/changerequests/:id/:privateToken/:stepName?', exact: false, secured: false, screen: 'private_changerequest'},
        {path: '/gimports/:id/:privateToken/:stepName?', exact: false, secured: false, screen: 'private_gimport'},
        {path: '/cbs/:type/:id', exact: true, secured: false, screen: 'public/public_callback'},
        {path: '/ridps/:id/register', exact: true, secured: false, screen: 'private_ridp'},
    ],
};

export default routes;