// noinspection JSUnusedGlobalSymbols

export const GET_PUBLIC_PARTNER_BY_CODE = (gql: any) => gql`
    query getPublicPartnerByCode($code: String!) {
        getPublicPartnerByCode(code: $code) {
            code
            name
            idps
            logoImage {
                url
                available
            }
        }
    }
`;
export const GET_PUBLIC_PARTNER_BY_PRIVATE_REGISTRATION = (gql: any) => gql`
    query getPublicPartnerByPrivateRegistration($registration: RegistrationAuthInput!) {
        getPublicPartnerByPrivateRegistration(registration: $registration) {
            code
            name
            idps
            gameRegisterWorkflow
            logoImage {
                url
                available
            }
        }
    }
`;
