const routes = {
    private: [
        {exact: false, secured: true, screen: 'private/game/private_game_layout', routes: [
            {path: '/', exact: true, secured: false, screen: 'private/game/private_game_home'},
            {path: '/manage', exact: true, secured: false, screen: 'private/game/private_game_manage'},
            {path: '/export', exact: true, secured: false, screen: 'private/game/private_game_export'},
            {path: '/game/edit-ticket-price', exact: false, secured: false, screen: 'private/game/private_game_game_edit_ticket_price'},
            {path: '/game/edit-notifications', exact: false, secured: false, screen: 'private/game/private_game_game_edit_notifications'},
            {path: '/game/edit-images', exact: false, secured: false, screen: 'private/game/private_game_game_edit_images'},
            {path: '/game/integrations/payment', exact: true, secured: false, screen: 'private/game/private_game_game_integrations_payment'},
            {path: '/game/integrations/payment/:moduleName', exact: true, secured: false, screen: 'private/game/private_game_game_integrations_payment_details'},
            {path: '/account/user/edit', exact: false, secured: false, screen: 'private/game/private_game_account_user_edit'},
            {path: '/account/user', exact: false, secured: false, screen: 'private/game/private_game_account_user'},
            {path: '/account/organization/edit-urls', exact: false, secured: false, screen: 'private/game/private_game_account_organization_edit_urls'},
            {path: '/account/organization/edit-type', exact: false, secured: false, screen: 'private/game/private_game_account_organization_edit_type'},
            {path: '/account/organization/edit-logo-image', exact: false, secured: false, screen: 'private/game/private_game_account_organization_edit_logo_image'},
            {path: '/account/organization/edit-banner-image', exact: false, secured: false, screen: 'private/game/private_game_account_organization_edit_banner_image'},
            {path: '/account/organization/edit', exact: false, secured: false, screen: 'private/game/private_game_account_organization_edit'},
            {path: '/account/organization', exact: false, secured: false, screen: 'private/game/private_game_account_organization'},
            {path: '/account', exact: false, secured: false, screen: 'private/game/private_game_account'},
            {path: '/game/blocks/:blockName', exact: false, secured: false, screen: 'private/game/private_game_blocks_block'},
            {path: '/game/blocks', exact: true, secured: false, screen: 'private/game/private_game_blocks'},
            {path: '/game/:stepName?', exact: false, secured: false, screen: 'private/game/private_game_game'},
            {path: '/links', exact: false, secured: false, screen: 'private/game/private_game_links'},
            {path: '/bunches/public-page-display-mode', exact: false, secured: false, screen: 'private/game/private_game_bunches_public_page_display_mode'},
            {path: '/bunches/collect-mode', exact: false, secured: false, screen: 'private/game/private_game_bunches_collect_mode'},
            {path: '/bunches/edit', exact: false, secured: false, screen: 'private/game/private_game_bunches_edit'},
            {path: '/bunches', exact: false, secured: false, screen: 'private/game/private_game_bunches'},
            {path: '/tickets', exact: true, secured: false, screen: 'private/game/private_game_tickets'},
            {path: '/tickets/draw-tickets', exact: true, secured: false, screen: 'private/game/private_game_tickets_draw_tickets'},
            {path: '/tickets/tickets', exact: true, secured: false, screen: 'private/game/private_game_tickets_tickets'},
            {path: '/tickets/tickets/:id/:stepName?', exact: true, secured: false, screen: 'private/game/private_game_ticket'},
            {path: '/tickets/books', exact: true, secured: false, screen: 'private/game/private_game_tickets_books'},
            {path: '/tickets/books/:id', exact: true, secured: false, screen: 'private/game/private_game_tickets_books_details'},
            {path: '/tickets/booksets', exact: true, secured: false, screen: 'private/game/private_game_tickets_booksets'},
            {path: '/tickets/booksets/:id', exact: true, secured: false, screen: 'private/game/private_game_tickets_booksets_details'},
            {path: '/sales', exact: true, secured: false, screen: 'private/game/private_game_sales'},
            {path: '/sales/sales', exact: true, secured: false, screen: 'private/game/private_game_sales_sales'},
            {path: '/sales/sales/:id', exact: true, secured: false, screen: 'private/game/private_game_sales_sales_details'},
            {path: '/sales/preorders', exact: true, secured: false, screen: 'private/game/private_game_sales_preorders'},
            {path: '/sales/preorders/:id', exact: true, secured: false, screen: 'private/game/private_game_sales_preorders_details'},
            {path: '/sales/promises', exact: true, secured: false, screen: 'private/game/private_game_sales_promises'},
            {path: '/sales/promises/:id', exact: true, secured: false, screen: 'private/game/private_game_sales_promises_details'},
            {path: '/sales/bookpayments', exact: true, secured: false, screen: 'private/game/private_game_sales_bookpayments'},
            {path: '/sales/bookpayments/:id', exact: true, secured: false, screen: 'private/game/private_game_sales_bookpayments_details'},
            {path: '/fundraising', exact: true, secured: false, screen: 'private/game/private_game_fundraising'},
            {path: '/fundraising/payment-integrations', exact: true, secured: false, screen: 'private/game/private_game_fundraising_integrations_payment'},
            {path: '/fundraising/payment-integrations/:moduleName', exact: true, secured: false, screen: 'private/game/private_game_fundraising_integrations_payment_details'},
            {path: '/fundraising/payments', exact: true, secured: false, screen: 'private/game/private_game_fundraising_payments'},
            {path: '/fundraising/payments/:id', exact: true, secured: false, screen: 'private/game/private_game_fundraising_payments_details'},
            {path: '/data', exact: true, secured: false, screen: 'private/game/private_game_data'},
            {path: '/help/contact', exact: true, secured: false, screen: 'private/game/private_game_help_contact_us'},
            {path: '/help', exact: true, secured: false, screen: 'private/game/private_game_help'},
            {path: '/changerequest', exact: true, secured: false, screen: 'private/game/private_game_changerequest'},
            {path: '/billing/plan/:code', exact: true, secured: false, screen: 'private/game/private_game_billing_plan'},
            {path: '/billing/manage', exact: true, secured: false, screen: 'private/game/private_game_billing_manage'},
            {path: '/billing', exact: true, secured: false, screen: 'private/game/private_game_billing'},
            {path: '/sellers/sellergroups', exact: true, secured: false, screen: 'private/game/private_game_sellers_sellergroups'},
            {path: '/sellers/sellergroups/sellergroups', exact: true, secured: false, screen: 'private/game/private_game_sellers_sellergroups_sellergroups'},
            {path: '/sellers/sellergroups/rankings', exact: true, secured: false, screen: 'private/game/private_game_sellers_sellergroups_rankings'},
            {path: '/sellers/sellergroups/rankings/:dimension', exact: true, secured: false, screen: 'private/game/private_game_sellers_sellergroups_rankings_by_dimension'},
            {path: '/sellers/sellergroups/:id/:stepName?', exact: true, secured: false, screen: 'private/game/private_game_sellergroup'},
            {path: '/sellers/sellers', exact: true, secured: false, screen: 'private/game/private_game_sellers_sellers'},
            {path: '/sellers/sellers/sellers', exact: true, secured: false, screen: 'private/game/private_game_sellers_sellers_sellers'},
            {path: '/sellers/sellers/rankings', exact: true, secured: false, screen: 'private/game/private_game_sellers_sellers_rankings'},
            {path: '/sellers/sellers/rankings/:dimension', exact: true, secured: false, screen: 'private/game/private_game_sellers_sellers_rankings_by_dimension'},
            {path: '/sellers/sellers/:id/:stepName?', exact: true, secured: false, screen: 'private/game/private_game_seller'},
            {path: '/sellers', exact: true, secured: false, screen: 'private/game/private_game_sellers'},
            {path: '/godfathers/godfathers', exact: true, secured: false, screen: 'private/game/private_game_godfathers_godfathers'},
            {path: '/godfathers/godfathers/rankings', exact: true, secured: false, screen: 'private/game/private_game_godfathers_godfathers_rankings'},
            {path: '/godfathers/godfathers/rankings/:period/:dimension', exact: true, secured: false, screen: 'private/game/private_game_godfathers_godfathers_rankings_by_period_and_dimension'},
            {path: '/godfathers/godfathers/:id/:stepName?', exact: true, secured: false, screen: 'private/game/private_game_godfather'},
            {path: '/godfathers', exact: true, secured: false, screen: 'private/game/private_game_godfathers'},
            {path: '/sponsorings/edit', exact: false, secured: false, screen: 'private/game/private_game_sponsorings_edit'},
            {path: '/sponsorings', exact: true, secured: false, screen: 'private/game/private_game_sponsorings'},
            {path: '/sponsorings/public-page-display-mode', exact: false, secured: false, screen: 'private/game/private_game_sponsorings_public_page_display_mode'},
            {path: '/tickets-import/:gameTicketsPrivateToken', exact: true, secured: false, screen: 'private/game/private_game_tickets_import'},
        ]}],
    publate: [
        {exact: true, secured: false, screen: 'publate/game/publate_game_autologin'},
    ],
};

export default routes;